<template>
  <el-dialog v-model="visible" width="30%" @close="$emit('close-emailer')">
    <div class="dialog-content">
      {{ $t('pages.transactions.all.table.subtable.dialog.content') }}:
    </div>

    <div v-if="showSearch && searchResource">
      <span class="el-form-item__label" v-text="searchText" />
      <el-row>
        <remote-search-select
          v-model="selectectedResource"
          fetch-handler="getAll"
          :resource="searchResource"
          :compute-name="searchResultFormatter"
          :modify-query="
            (q) => ({
              query: {
                deleted: false,
                q
              }
            })
          "
          @update:modelValue="handleSearchSelectInput"
          @resource-set="handleSearchResourceSet"
        />
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" @submit.prevent>
      <el-form-item
        prop="email"
        :label="$t('components.receipt_mailer.label.prompt_type')"
      >
        <el-input
          v-model="form.email"
          :placeholder="
            $t('pages.transactions.all.table.subtable.dialog.email_placeholder')
          "
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('cancel-emailer')">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button v-loading="loading" type="primary" @click="emailReceipt">
          {{ $t('common.interactions.buttons.email') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    receipts: {
      type: Array,
      default: () => []
    },
    cart: {
      type: Object,
      required: true,
      default: () => ({})
    },
    defaultCustomerEmail: {
      type: String,
      required: false,
      default: null
    },
    defaultCustomerName: {
      type: String,
      required: false,
      default: null
    },
    lines: {
      type: Array,
      default: () => []
    },
    date: {
      type: String,
      default: () => {}
    },
    types: {
      type: Array,
      required: true,
      default: () => ['customer']
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    searchResource: {
      type: String,
      required: false,
      default: undefined
    },
    searchResultFormatter: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        email: null
      },
      selectectedResource: null,
      rules: {
        email: [
          {
            required: true,
            type: 'email',
            message: this.$t(
              'pages.transactions.all.table.subtable.dialog.email_invalid'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    searchText() {
      const resource = {
        customer: this.$t('common.resources.customer'),
        customers: this.$t('common.resources.customers')
      }[this.searchResource]

      return this.$t('components.receipt_mailer.label.search_for', { resource })
    }
  },
  watch: {
    open: function (newVal) {
      this.visible = newVal
    }
  },
  created() {
    this.visible = this.open
  },
  mounted() {
    this.form.email = this.defaultCustomerEmail
    this.selectectedResource = this.defaultCustomerName
  },
  methods: {
    validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    },
    emailReceipt(index) {
      const inst = th.notifications()

      this.loading = true

      this.validate(async (valid) => {
        if (!valid) {
          this.loading = false

          return this.$message({
            type: 'warning',
            message: this.$t(
              'pages.transactions.all.table.subtable.dialog.form_invalid'
            )
          })
        }

        const receiptInfo = this.receipts.find((receipt) =>
          this.types.includes(receipt.type)
        )

        const options = {
          type: 'receipts',
          body: {
            transaction_date: this.date,
            email: this.form.email,
            receipt_text: receiptInfo && receiptInfo.text,
            text_items: {
              cart: this.cart
            }
          }
        }

        if (this.lines.length) options.body.lines = this.lines

        try {
          await inst.email(options)

          this.$message({
            type: 'success',
            message: this.$t(
              'pages.transactions.all.table.subtable.warnings.email_sent'
            )
          })
        } catch (err) {
          this.$logException(err)

          this.$message({
            type: 'warning',
            message: this.$t(
              'pages.transactions.all.table.subtable.warnings.email_failed'
            )
          })
        }

        this.loading = false
        this.$refs.form.resetFields()
        this.$emit('email-sent')
      })
    },
    handleSearchSelectInput(v) {},
    handleSearchResourceSet(v) {
      if (!v) return
      if (!v.email) {
        this.form.email = null
        return
      }

      this.form.email = v.email
    },
    handleSearchEnter() {}
  }
}
</script>

<style scoped>
.dialog-content {
  margin-bottom: 10px;
}
.search-input {
  width: 100%;
}
</style>
